<template>
  <div class="p-field p-col-12 p-lg-12">
    <h3><b>Assunto: </b>{{ messageInView.subject }}</h3>
    <b>De: </b>{{ messageInView.sender_name }} -
    {{ formatTime(messageInView.created_at) }}
    <div v-if="messageInView.project_id">
      <b>Projeto: </b> {{ messageInView.project_name }}
    </div>
    <div
      v-if="messageInView.attachments.length > 0"
      class="p-grid p-col-12 p-lg-12 p-mt-2"
    >
      <Button
        v-for="attachment in messageInView.attachments"
        :key="attachment.id"
        :label="attachment.file_original_name"
        icon="pi pi-paperclip"
        class="p-button-sm p-mr-1"
        @click="getAttachment(attachment)"
      />
    </div>
    <Divider />
    <div v-html="messageInView.message_body" />
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import msgService from "../services/message.service";

export default {
  name: "MessageView",
  props: ["messageInView"],
  data() {
    return {};
  },
  async created() {},
  methods: {
    formatTime(dateTime) {
      return moment(dateTime).format("DD-MM-YYYY HH:mm");
    },
    getAttachment(attachment) {
      msgService
        .getAttachement(attachment.message_id, attachment.id)
        .then((response) => {
          let url = window.URL.createObjectURL(new Blob([response]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", attachment.file_original_name);
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
</script>
