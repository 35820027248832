<template>
  <div class="card p-shadow-6">
    <div class="p-grid p-col-12 p-lg-12">
      <h3>
        As minhas Mensagens <strong>{{ currentUser.username }}</strong>
      </h3>
      <Divider />
      <div class="p-field p-col-12 p-md-6">
        <Button
          label="Nova Mensagem"
          icon="pi pi-plus-circle"
          class="p-button-sm p-mb-2"
          @click="newMessage()"
        />
        <Button
          label="Caixa de Entrada"
          icon="pi pi-inbox"
          class="p-button-sm p-button-success p-mb-2 p-ml-2"
          @click="goInbox()"
        />

        <Button
          label="Caixa de Saída"
          icon="pi pi-arrow-up-right"
          class="p-button-sm p-button-success p-mb-2 p-ml-2"
          @click="goOutbox()"
        />
        <h3>{{ boxText }}</h3>
        <DataTable
          :value="messageList"
          :filters="filters"
          :loading="loading"
          :paginator="true"
          :rows="15"
          class="p-datatable-sm p-datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 25, 50]"
          currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords} instalações"
          :sortOrder="1"
          editMode="row"
          dataKey="id"
          v-if="boxText == 'Caixa de Entrada'"
        >
          <template #empty> Nada a mostrar. </template>
          <template #loading> A carregar. Por favor Aguarde... </template>
          <Column
            field="sender_name"
            header="De"
            sortable
            filterMatchMode="contains"
          >
            <template #body="slotProps">
              <span class="p-column-title">De:</span>
              {{ slotProps.data.sender_name }}
            </template>
            <template #filter>
              <InputText
                type="text"
                v-model="filters['sender_name']"
                class="p-column-filter"
              />
            </template>
          </Column>
          <Column
            field="subject"
            header="Assunto"
            sortable
            filterMatchMode="contains"
          >
            <template #body="slotProps">
              <span class="p-column-title">Assunto:</span>
              {{ slotProps.data.subject }}
            </template>
            <template #filter>
              <InputText
                type="text"
                v-model="filters['subject']"
                class="p-column-filter"
              />
            </template>
          </Column>
          <Column
            field="created_at"
            header="Data"
            sortable
            filterMatchMode="contains"
          >
            <template #body="slotProps">
              <span class="p-column-title">Data:</span>
              {{ formatTime(slotProps.data.created_at) }}
            </template>
            <template #filter>
              <InputText
                type="text"
                v-model="filters['created_at']"
                class="p-column-filter"
              />
            </template>
          </Column>
          <Column
            field="is_read"
            header="Lida"
            filterMatchMode="equals"
            sortable
          >
            <template #body="slotProps">
              <span class="p-column-title">Lida:</span>
              {{ slotProps.data.is_read == 1 ? "Sim" : "Não" }}
            </template>
            <template #filter>
              <Dropdown
                v-model="filters['is_read']"
                :options="[
                  { value: 0, label: 'Não' },
                  { value: 1, label: 'Sim' },
                ]"
                optionValue="value"
                optionLabel="label"
                class="p-column-filter"
                :showClear="true"
              >
              </Dropdown>
            </template>
          </Column>

          <Column :headerStyle="{ width: '80px' }">
            <template #body="slotProps">
              <Button
                v-if="slotProps.data.is_read == 1"
                v-tooltip.left="'Marcar como não lido'"
                icon="pi pi-envelope"
                class="p-button-sm p-button-rounded p-button-text"
                @click="changeReadStatus(slotProps.data, 0)"
              />
              <Button
                v-tooltip.left="'Ver'"
                icon="pi pi-eye"
                class="p-button-sm p-button-rounded p-button-text"
                @click="viewMessage(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <DataTable
          :value="messageList"
          :filters="filters"
          :loading="loading"
          :paginator="true"
          :rows="15"
          class="p-datatable-sm p-datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 25, 50]"
          currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords} instalações"
          :sortOrder="1"
          :autoLayout="true"
          editMode="row"
          dataKey="id"
          v-if="boxText == 'Caixa de Saída'"
        >
          <template #empty> Nada a mostrar. </template>
          <template #loading> A carregar. Por favor Aguarde... </template>
          <Column
            field="receiver_name"
            header="Para"
            sortable
            filterMatchMode="contains"
          >
            <template #body="slotProps">
              <span class="p-column-title">Para:</span>
              {{ slotProps.data.receiver_name }}
            </template>
            <template #filter>
              <InputText
                type="text"
                v-model="filters['receiver_name']"
                class="p-column-filter"
              />
            </template>
          </Column>
          <Column
            field="subject"
            header="Assunto"
            sortable
            filterMatchMode="contains"
          >
            <template #body="slotProps">
              <span class="p-column-title">Assunto:</span>
              {{ slotProps.data.subject }}
            </template>
            <template #filter>
              <InputText
                type="text"
                v-model="filters['subject']"
                class="p-column-filter"
              />
            </template>
          </Column>
          <Column
            field="created_at"
            header="Data"
            sortable
            filterMatchMode="contains"
          >
            <template #body="slotProps">
              <span class="p-column-title">Data:</span>
              {{ formatTime(slotProps.data.created_at) }}
            </template>
            <template #filter>
              <InputText
                type="text"
                v-model="filters['created_at']"
                class="p-column-filter"
              />
            </template>
          </Column>

          <Column :headerStyle="{ width: '40px' }">
            <template #body="slotProps">
              <Button
                v-tooltip.left="'Ver'"
                icon="pi pi-eye"
                class="p-button-sm p-button-rounded p-button-text"
                @click="viewMessage(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
      <Divider layout="vertical" />
      <div class="p-field p-col-12 p-md-5">
        <MessageView :messageInView="messageInView" v-if="showMessage" />
        <MessageNew
          v-if="createNewMessage"
          v-on:closeNewMessage="closeNewMessage"
        />
        <MessageConversation
          :mainId="conversationId"
          v-if="showConversationMessage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import msgService from "../services/message.service";
import moment from "moment";
import MessageView from "./MessagesView.vue";
import MessageNew from "./MessagesNew.vue";
import MessageConversation from "./MessagesConversation.vue";

export default {
  name: "Messages",
  components: {
    MessageView,
    MessageNew,
    MessageConversation,
  },
  data() {
    return {
      boxText: "Caixa de Entrada",
      loading: true,
      filters: {is_read: 0},
      messageList: [],
      showMessage: false,
      createNewMessage: false,
      showConversationMessage: false,
      messageInView: {},
      conversationId: null,
    };
  },
  computed: {
    currentUser() {
      if (this.$store.state.auth.status.loggedIn) {
        return this.$store.state.auth.user;
      }
      return null;
    },
  },
  async created() {
    this.messageList = await this.getMessages();
    this.loading = false;
  },
  methods: {
    getMessages() {
      return msgService.getMessages(this.$store.state.auth.user.id);
    },
    getOutMessages() {
      return msgService.getOutMessages(this.$store.state.auth.user.id);
    },
    formatTime(dateTime) {
      return moment(dateTime).format("DD-MM-YYYY HH:mm");
    },
    changeReadStatus(message, status) {
      let bodyparams = {
        readStatus: status,
      };
      return msgService
        .changeReadStatus(message.id, bodyparams)
        .then((response) => {
          if (response) {
            message.is_read = status;
          }
        });
    },
    async viewMessage(message) {
      if (!message.is_read) {
        await this.changeReadStatus(message, 1);
      }
      this.createNewMessage = false;
      this.showMessage = false;
      this.showConversationMessage = false;
      if (message.wait_reply == 1 || message.parent_id != null) {
        this.conversationId = message.id;
        return (this.showConversationMessage = true);
      }

      this.messageInView = message;
      return (this.showMessage = true);
    },
    newMessage() {
      this.showMessage = false;
      this.showConversationMessage = false;
      this.createNewMessage = true;
    },
    closeNewMessage() {
      return (this.createNewMessage = false);
    },
    async goInbox() {
      this.loading = true;
      this.boxText = "Caixa de Entrada";
      this.messageList = await this.getMessages();
      this.loading = false;
    },
    async goOutbox() {
      this.loading = true;
      this.boxText = "Caixa de Saída";
      this.messageList = await this.getOutMessages();
      this.loading = false;
    },
  },
};
</script>
<style scoped lang="scss">
.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.p-column-filter {
  width: 100%;
}

</style>
