<template>
  <div class="p-field p-col-12 p-lg-12">
    <h3><b>Assunto: </b> {{ conversation.subject }}</h3>
    <b>De: </b> {{ conversation.sender_name }} -
    {{ formatTime(conversation.created_at) }}

    <div v-if="conversation.project_id">
      <b>Projeto: </b> {{ conversation.project_name }}
    </div>
    <Divider />
    <PrimeVueTimeline
      :value="conversation.childrens"
      align="alternate"
      class="customized-timeline"
    >
      <template #marker>
        <span class="custom-marker p-shadow-4">
          <i :class="'pi pi-comment'"></i>
        </span>
      </template>
      <template #content="slotProps">
        <Card
          v-bind:class="[
            { 'current-msg': slotProps.item.id == mainId },
            'p-shadow-4',
          ]"
          class="p-shadow-4"
        >
          <template #title>
            {{ slotProps.item.sender_name }}
          </template>
          <template #subtitle>
            {{ formatTime(slotProps.item.created_at) }}
          </template>
          <template #content>
            <div v-html="slotProps.item.message_body" />
            <div class="p-grid p-col-12 p-lg-12 p-mt-2">
              <Button
                v-for="attachment in slotProps.item.attachments"
                :key="attachment.id"
                :label="attachment.file_original_name"
                icon="pi pi-paperclip"
                class="p-button-sm p-mt-1"
                @click="getAttachment(attachment)"
              />
            </div>
          </template>
        </Card>
      </template>
    </PrimeVueTimeline>
    <Divider />
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12 p-mt-">
        <Editor
          name="body"
          v-model="newMessage"
          editorStyle="height: 100px"
          v-validate="'required'"
        >
          <template #toolbar>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
              <button
                class="pi pi-paperclip"
                @click="toggleAttachments()"
              ></button>
            </span>
          </template>
        </Editor>
        <FileUpload
          v-if="showAttachments"
          ref="uploadInput"
          name="attachments"
          class="p-mt-1"
          chooseLabel="Adicionar Anexo"
          :multiple="true"
          :showUploadButton="false"
          :showCancelButton="false"
          :maxFileSize="2000000000"
        />
      </div>
    </div>
    <Button
      label="Enviar"
      icon="pi pi-send"
      class="p-button-sm p-button-success"
      @click="sendMessage()"
    />
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import msgService from "../services/message.service";
import PrimeVueTimeline from "primevue/timeline";

export default {
  name: "MessagesConversation",
  components: {
    PrimeVueTimeline,
  },
  props: ["mainId"],
  watch: {
    mainId: {
      deep: true,
      immediate: true,
      handler() {
        this.getConversation();
      },
    },
  },
  data() {
    return {
      conversation: {},
      newMessage: "",
      showAttachments: false,
    };
  },
  methods: {
    formatTime(dateTime) {
      return moment(dateTime).format("DD-MM-YYYY HH:mm");
    },
    getConversation() {
      return msgService
        .getConversation(this.mainId)
        .then((response) => {
          return (this.conversation = response);
        })
        .then((conversation) => {
          this.conversation.childrens.unshift({
            created_at: conversation.created_at,
            id: conversation.id,
            is_read: conversation.is_read,
            message_body: conversation.message_body,
            read_at: conversation.read_at,
            receiver_id: conversation.receiver_id,
            receiver_name: conversation.receiver_name,
            sender_id: conversation.sender_id,
            sender_name: conversation.sender_name,
            attachments: conversation.attachments,
          });
        });
    },
    toggleAttachments() {
      if (this.showAttachments) {
        return this.showAttachments = false;
      }
      return this.showAttachments = true;
    },
    sendMessage() {
      if (this.newMessage == "") {
        return this.$toast.add({
          severity: "error",
          summary: "Mensagem Vazia",
          detail: "A mensagem está vazia",
          life: 3000,
        });
      }

      var formData = new FormData();
      formData.append("subject", this.conversation.subject);
      formData.append("message_body", this.newMessage);
      formData.append("is_private", this.conversation.is_private);
      formData.append("wait_reply", this.conversation.wait_reply);
      formData.append("parent_id", this.conversation.id);
      formData.append(
        "receiver_id",
        this.conversation.receiver_id == this.mainId
          ? this.conversation.sender_id
          : this.conversation.receiver_id
      );

      if (this.conversation.project_id) {
        formData.append("project_id", this.conversation.project_id);
      }

      if (this.$refs.uploadInput != undefined && this.$refs.uploadInput.files.length > 0) {
          this.$refs.uploadInput.files.forEach((file) => {
            formData.append("attachments[]", file);
          });
        }
      msgService.sendMessage(formData).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro ao envia mensagem",
            detail: "Ocorreu um erro ao enviar a mensagem",
            life: 3000,
          });
        }

        this.$toast.add({
          severity: "success",
          summary: "Menssagem Enviada",
          detail: "A mensagem foi enviada com sucesso",
          life: 3000,
        });

        this.newMessage = "";
        if(this.$refs.uploadInput != undefined && this.$refs.uploadInput.files.length > 0) {
          this.$refs.uploadInput.clear();
          this.showAttachments = false;
        }
        return this.getConversation();
      });
    },
    getAttachment(attachment) {
      msgService
        .getAttachement(attachment.message_id, attachment.id)
        .then((response) => {
          let url = window.URL.createObjectURL(new Blob([response]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", attachment.file_original_name);
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
</script>
<style scoped>
.custom-marker {
  display: -webkit-;
  display: -ms-flexbox;
  display: flex;
  width: 2rem;
  height: 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  z-index: 1;
  background-color: #ff4910;
}

.current-msg {
  background-color: #b0b0b0;
}
</style>
