var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-col-12 p-md-12",staticStyle:{"position":"relative"}},[_c('Loading',{attrs:{"active":_vm.isLoading,"isFullPage":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('Toast',{style:({ width: _vm.$isMobile() ? '85vw' : '' })}),_vm._m(0),_c('form',{staticClass:"p-col-12",attrs:{"name":"newMessageForm"},on:{"submit":function($event){$event.preventDefault();return _vm.sendMessage.apply(null, arguments)}}},[_c('div',{staticClass:"p-fluid p-formgrid p-grid"},[_c('div',{staticClass:"p-field p-col-12 p-md-12 p-mt-2"},[_c('span',{staticClass:"p-float-label"},[_c('MultiSelect',{directives:[{name:"validate",rawName:"v-validate",value:(
              _vm.messageData.toDepartment != undefined &&
              _vm.messageData.toDepartment.length > 0
                ? ''
                : 'required'
            ),expression:"\n              messageData.toDepartment != undefined &&\n              messageData.toDepartment.length > 0\n                ? ''\n                : 'required'\n            "}],class:[
              { 'p-invalid': _vm.errors.has('toId') },
              'form-control',
            ],attrs:{"name":"toId","options":_vm.activesUsers,"optionLabel":"username","optionValue":"id","filter":true,"display":"chip","disabled":_vm.messageData.toDepartment != undefined &&
              _vm.messageData.toDepartment.length > 0
                ? true
                : false},model:{value:(_vm.messageData.toId),callback:function ($$v) {_vm.$set(_vm.messageData, "toId", $$v)},expression:"messageData.toId"}}),_c('label',{attrs:{"for":"inputtext"}},[_vm._v("Para o Utilizador:")])],1),(_vm.errors.has('toId'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Selecione o Utilizador ou o Departamento ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-12 p-mt-2"},[_c('span',{staticClass:"p-float-label"},[_c('MultiSelect',{directives:[{name:"validate",rawName:"v-validate",value:(
              _vm.messageData.toId != undefined && _vm.messageData.toId.length > 0
                ? ''
                : 'required'
            ),expression:"\n              messageData.toId != undefined && messageData.toId.length > 0\n                ? ''\n                : 'required'\n            "}],class:[
              { 'p-invalid': _vm.errors.has('toDepartment') },
              'form-control',
            ],attrs:{"name":"toDepartment","options":_vm.departments,"filter":true,"display":"chip","disabled":_vm.messageData.toId != undefined && _vm.messageData.toId.length > 0
                ? true
                : false},model:{value:(_vm.messageData.toDepartment),callback:function ($$v) {_vm.$set(_vm.messageData, "toDepartment", $$v)},expression:"messageData.toDepartment"}}),_c('label',{attrs:{"for":"inputtext"}},[_vm._v("Para o Departamento:")])],1),(_vm.errors.has('toDepartment'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Selecione o Departamento ou o Utilizador ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-6 p-md-1 p-mt-4"},[_vm._m(1),_c('InputSwitch',{staticClass:"p-mt-1",attrs:{"name":"private"},model:{value:(_vm.messageData.isPrivate),callback:function ($$v) {_vm.$set(_vm.messageData, "isPrivate", $$v)},expression:"messageData.isPrivate"}})],1),_c('div',{staticClass:"p-field p-col-6 p-md-1 p-ml-1 p-mt-4"},[_vm._m(2),_c('InputSwitch',{staticClass:"p-mt-1",attrs:{"name":"waitReply"},model:{value:(_vm.messageData.waitReply),callback:function ($$v) {_vm.$set(_vm.messageData, "waitReply", $$v)},expression:"messageData.waitReply"}})],1),_c('div',{staticClass:"p-field p-col-6 p-md-1 p-ml-2 p-mt-4"},[_vm._m(3),_c('InputSwitch',{staticClass:"p-mt-1",attrs:{"name":"waitReply"},on:{"click":_vm.getInstallations},model:{value:(_vm.showProjects),callback:function ($$v) {_vm.showProjects=$$v},expression:"showProjects"}})],1),(_vm.showProjects)?_c('div',{staticClass:"p-field p-col-12 p-md-12 p-mt-2"},[_c('span',{staticClass:"p-float-label"},[_c('Dropdown',{attrs:{"options":_vm.projectsList,"optionValue":'id',"optionLabel":_vm.getProjectsLabel,"filter":true,"showClear":true},scopedSlots:_vm._u([{key:"option",fn:function(slotProps){return [_c('div',{class:[
                  { 'options-search': !_vm.$isMobile() },
                  { 'options-search-responsive': _vm.$isMobile() },
                  { 'p-ml-2': _vm.$isMobile() },
                ]},[_c('b',[_vm._v("Nome: ")]),_vm._v(" "+_vm._s(slotProps.option.name)+" "),_c('br'),_c('b',[_vm._v("Descrição: ")]),_vm._v(" "+_vm._s(slotProps.option.description)+" "),_c('br'),_c('b',[_vm._v("Estado: ")]),_vm._v(" "+_vm._s(slotProps.option.concluded == true ? "Fechado" : "Aberto")+" ")])]}}],null,false,54332893),model:{value:(_vm.messageData.projectId),callback:function ($$v) {_vm.$set(_vm.messageData, "projectId", $$v)},expression:"messageData.projectId"}}),_c('label',{attrs:{"for":"inputtext"}},[_vm._v("Projeto")])],1)]):_vm._e(),_c('div',{staticClass:"p-field p-col-12 p-md-12 p-mt-2"},[_c('span',{staticClass:"p-float-label"},[_c('InputText',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
              { 'p-invalid': _vm.errors.has('subject') },
              'form-control',
            ],attrs:{"name":"subject","type":"text"},model:{value:(_vm.messageData.subject),callback:function ($$v) {_vm.$set(_vm.messageData, "subject", $$v)},expression:"messageData.subject"}}),_c('label',{attrs:{"for":"subject"}},[_vm._v("Assunto")])],1),(_vm.errors.has('subject'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" O Assunto é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-12 p-mt-2"},[_c('FileUpload',{ref:"uploadInput",attrs:{"name":"formValuesTraining.files[]","chooseLabel":"Adicionar Anexo","multiple":true,"showUploadButton":false,"showCancelButton":false,"maxFileSize":2000000000}})],1),_c('div',{staticClass:"p-field p-col-12 p-md-12"},[_c('Editor',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[{ 'p-invalid': _vm.errors.has('body') }, 'form-control'],attrs:{"name":"body","editorStyle":"height: 200px"},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('span',{staticClass:"ql-formats"},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-italic"}),_c('button',{staticClass:"ql-underline"})])]},proxy:true}]),model:{value:(_vm.messageData.body),callback:function ($$v) {_vm.$set(_vm.messageData, "body", $$v)},expression:"messageData.body"}})],1),(_vm.errors.has('body'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" A mensagem encontra-se vazia ")]):_vm._e()])]),_c('Button',{staticClass:"p-button-sm p-button-success",attrs:{"label":"Enviar","icon":"pi pi-send"},on:{"click":function($event){return _vm.sendMessage()}}}),_c('Button',{staticClass:"p-button-sm p-button-danger p-ml-2",attrs:{"label":"Cancelar","icon":"pi pi-times"},on:{"click":function($event){return _vm.closeNewMessage()}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v("Nova Mensagem")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"p-float-label",staticStyle:{"top":"-0.75rem","left":"-1rem","font-size":"12px"}},[_c('label',{attrs:{"for":"private"}},[_vm._v("Privada")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"p-float-label",staticStyle:{"top":"-1.4rem","left":"-1rem","font-size":"12px"}},[_c('label',{attrs:{"for":"waitReply"}},[_vm._v("Aguarda Resposta")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"p-float-label",staticStyle:{"top":"-1.4rem","left":"-1rem","font-size":"12px"}},[_c('label',{attrs:{"for":"waitReply"}},[_vm._v("Associar Projeto")])])
}]

export { render, staticRenderFns }