<template>
  <div class="p-col-12 p-md-12" style="position: relative">
    <Loading :active.sync="isLoading" :isFullPage="false"></Loading>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <h3><b>Nova Mensagem</b></h3>
    <form name="newMessageForm" class="p-col-12" @submit.prevent="sendMessage">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12 p-mt-2">
          <span class="p-float-label">
            <MultiSelect
              name="toId"
              v-model="messageData.toId"
              :options="activesUsers"
              optionLabel="username"
              optionValue="id"
              :filter="true"
              display="chip"
              v-validate="
                messageData.toDepartment != undefined &&
                messageData.toDepartment.length > 0
                  ? ''
                  : 'required'
              "
              :disabled="
                messageData.toDepartment != undefined &&
                messageData.toDepartment.length > 0
                  ? true
                  : false
              "
              v-bind:class="[
                { 'p-invalid': errors.has('toId') },
                'form-control',
              ]"
            />
            <label for="inputtext">Para o Utilizador:</label>
          </span>
          <small v-if="errors.has('toId')" class="p-error" role="alert">
            Selecione o Utilizador ou o Departamento
          </small>
        </div>

        <div class="p-field p-col-12 p-md-12 p-mt-2">
          <span class="p-float-label">
            <MultiSelect
              name="toDepartment"
              v-model="messageData.toDepartment"
              :options="departments"
              :filter="true"
              display="chip"
              :disabled="
                messageData.toId != undefined && messageData.toId.length > 0
                  ? true
                  : false
              "
              v-validate="
                messageData.toId != undefined && messageData.toId.length > 0
                  ? ''
                  : 'required'
              "
              v-bind:class="[
                { 'p-invalid': errors.has('toDepartment') },
                'form-control',
              ]"
            />
            <label for="inputtext">Para o Departamento:</label>
          </span>
          <small v-if="errors.has('toDepartment')" class="p-error" role="alert">
            Selecione o Departamento ou o Utilizador
          </small>
        </div>

        <div class="p-field p-col-6 p-md-1 p-mt-4">
          <span
            class="p-float-label"
            style="top: -0.75rem; left: -1rem; font-size: 12px"
          >
            <label for="private">Privada</label>
          </span>
          <InputSwitch
            name="private"
            v-model="messageData.isPrivate"
            class="p-mt-1"
          />
        </div>

        <div class="p-field p-col-6 p-md-1 p-ml-1 p-mt-4">
          <span
            class="p-float-label"
            style="top: -1.4rem; left: -1rem; font-size: 12px"
          >
            <label for="waitReply">Aguarda Resposta</label>
          </span>
          <InputSwitch
            name="waitReply"
            v-model="messageData.waitReply"
            class="p-mt-1"
          />
        </div>

        <div class="p-field p-col-6 p-md-1 p-ml-2 p-mt-4">
          <span
            class="p-float-label"
            style="top: -1.4rem; left: -1rem; font-size: 12px"
          >
            <label for="waitReply">Associar Projeto</label>
          </span>
          <InputSwitch
            name="waitReply"
            v-model="showProjects"
            class="p-mt-1"
            @click="getInstallations"
          />
        </div>

        <div class="p-field p-col-12 p-md-12 p-mt-2" v-if="showProjects">
          <span class="p-float-label">
            <Dropdown
              v-model="messageData.projectId"
              :options="projectsList"
              :optionValue="'id'"
              :optionLabel="getProjectsLabel"
              :filter="true"
              :showClear="true"
            >
              <template #option="slotProps">
                <div
                  v-bind:class="[
                    { 'options-search': !$isMobile() },
                    { 'options-search-responsive': $isMobile() },
                    { 'p-ml-2': $isMobile() },
                  ]"
                >
                  <b>Nome: </b>
                  {{ slotProps.option.name }} <br />
                  <b>Descrição: </b>
                  {{ slotProps.option.description }} <br />
                  <b>Estado: </b>
                  {{
                    slotProps.option.concluded == true ? "Fechado" : "Aberto"
                  }}
                </div>
              </template>
            </Dropdown>
            <label for="inputtext">Projeto</label>
          </span>
        </div>

        <div class="p-field p-col-12 p-md-12 p-mt-2">
          <span class="p-float-label">
            <InputText
              name="subject"
              type="text"
              v-model="messageData.subject"
              v-validate="'required'"
              v-bind:class="[
                { 'p-invalid': errors.has('subject') },
                'form-control',
              ]"
            />
            <label for="subject">Assunto</label>
          </span>
          <small v-if="errors.has('subject')" class="p-error" role="alert">
            O Assunto é obrigatório
          </small>
        </div>

        <div class="p-field p-col-12 p-md-12 p-mt-2">
          <FileUpload
            ref="uploadInput"
            name="formValuesTraining.files[]"
            chooseLabel="Adicionar Anexo"
            :multiple="true"
            :showUploadButton="false"
            :showCancelButton="false"
            :maxFileSize="2000000000"
          />
        </div>
        <div class="p-field p-col-12 p-md-12">
          <Editor
            name="body"
            v-model="messageData.body"
            editorStyle="height: 200px"
            v-validate="'required'"
            v-bind:class="[{ 'p-invalid': errors.has('body') }, 'form-control']"
          >
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
              </span>
            </template>
          </Editor>
        </div>
        <small v-if="errors.has('body')" class="p-error" role="alert">
          A mensagem encontra-se vazia
        </small>
      </div>
    </form>
    <Button
      label="Enviar"
      icon="pi pi-send"
      class="p-button-sm p-button-success"
      @click="sendMessage()"
    />
    <Button
      label="Cancelar"
      icon="pi pi-times"
      class="p-button-sm p-button-danger p-ml-2"
      @click="closeNewMessage()"
    />
  </div>
</template>

<script>
import loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import userService from "../services/user.service";
import utilitiesService from "../services/utilities.service";
import msgService from "../services/message.service";
import installationservice from "../services/installations.service";

export default {
  name: "MessageNew",
  components: {
    Loading: loading,
  },
  data() {
    return {
      isLoading: true,
      showProjects: false,
      projectsList: [],
      activesUsers: [],
      departments: [],
      messageData: {},
    };
  },
  async created() {
    this.isLoading = true;
    this.activesUsers = await this.getActivesUsers();
    this.departments = await this.getDepartements();
    this.isLoading = false;
  },
  methods: {
    getActivesUsers() {
      return userService.activesUsers();
    },
    getDepartements() {
      return utilitiesService.getDepartments().then((response) => {
        response.unshift("Todos");
        return response;
      });
    },
    async getInstallations() {
      if (this.projectsList.length > 0) {
        return;
      }
      this.isLoading = true;

      return installationservice
        .getInstallationsWithComments()
        .then((response) => {
          this.projectsList = response;
          return;
        })
        .then(() => installationservice.getInstallationsWithoutComments(0))
        .then((resp) => {
          this.projectsList = this.projectsList.concat(resp);
          return (this.isLoading = false);
        });
    },
    sendMessage() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        var formData = new FormData();
        if (this.$refs.uploadInput.files.length > 0) {
          this.$refs.uploadInput.files.forEach((file) => {
            formData.append("attachments[]", file);
          });
        }
        formData.append("subject", this.messageData.subject);
        formData.append("message_body", this.messageData.body);
        formData.append(
          "is_private",
          this.messageData.isPrivate != undefined &&
            this.messageData.isPrivate == true
            ? 1
            : 0
        );
        formData.append(
          "wait_reply",
          this.messageData.waitReply != undefined &&
            this.messageData.waitReply == true
            ? 1
            : 0
        );

        if (
          this.showProjects == true &&
          this.messageData.projectId != undefined &&
          this.messageData.projectId != null
        ) {
          formData.append("project_id", this.messageData.projectId);
        }

        if (
          this.messageData.toId != undefined &&
          this.messageData.toId != null &&
          this.messageData.toId.length > 0
        ) {
          formData.append("receiver_id", "");
          this.messageData.toId.forEach((sentTo, index) => {
            formData.set("receiver_id", sentTo);
            this.send(formData);
            if (index + 1 == this.messageData.toId.length) {
              return setTimeout(this.closeNewMessage, 3000);
            }
          });
        } else if (
          this.messageData.toDepartment != undefined &&
          this.messageData.toDepartment != null &&
          this.messageData.toDepartment.length > 0
        ) {
          formData.append("receiver_group", "");
          this.messageData.toDepartment.forEach((sentToDpt, index) => {
            formData.set("receiver_group", sentToDpt);
            this.send(formData);
            if (index + 1 == this.messageData.toDepartment.length) {
              return setTimeout(this.closeNewMessage, 3000);
            }
          });
        } else {
          return this.$toast.add({
            severity: "error",
            summary: "Sem destinatário",
            detail: "A mensagem não tem destinatário",
            life: 3000,
          });
        }
      });
    },
    send(bodyParams) {
      return msgService.sendMessage(bodyParams).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro ao envia mensagem",
            detail: "Ocorreu um erro ao enviar a mensagem",
            life: 3000,
          });
        }

        this.$toast.add({
          severity: "success",
          summary: "Menssagem Enviada",
          detail: "A mensagem foi enviada com sucesso",
          life: 3000,
        });
        return response;
      });
    },
    closeNewMessage() {
      this.messageData = {};
      return this.$emit("closeNewMessage");
    },
    getProjectsLabel(info) {
      return `${info.name} ${info.description}`;
    },
  },
};
</script>
